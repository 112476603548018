.article5 {
   
}

.content{
    text-align:justify
}
.justCenter{
    padding-bottom: 1em;
}
.details{
    margin-bottom:0.3rem;
}


@media only screen and (max-width: 48.25em) { 
    .img{
        max-width:300px !important;
        max-height:200px !important;
    }
}
