.review{
        display: flex;
        flex-flow:column;
        align-items: center;
        gap: 80px;
        padding-top:2em;
        padding-bottom:2em;
        background-color:#f2f2f2;
        color:#545454;
        padding-right:5%;
        padding-left:5%;
    
}