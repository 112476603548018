.action8 {}.action5 {}

.content{
    text-align:center
}
.justCenter{
    padding-bottom: 1em;
}
.details{
    margin-bottom:0.3rem;
}