.banner {
    background-color:#90b2f5;
    border-radius:0.2em;
    height: 110px;
}
.stoixeia{
    color:#00069c !important;
    margin-bottom:0;
    padding-left:15%;
}
.fb{
    transform: scale(2.8);
}
@media only screen and (max-width: 36.25em) { 
    .stoixeia{
        text-align:left;
        padding-left:5%;
        font-size:13px;
    }
}
