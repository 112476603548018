.tilefona {
    padding-top:2em;
    padding-bottom: 2em;
    background-color:#f2f2f2;
    color:#545454;
}
@media only screen and (max-width: 48.125em) { 
    .txt{
        font-size:14px;
    }
}
@media only screen and (max-width: 36.25em) { 
    .txt{
        font-size:10px;
    }
}
