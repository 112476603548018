.newsfeed {}
.content{
    text-align:justify
}
.justCenter{
    padding-bottom: 1em;
}
.details{
    margin-bottom:0.3rem;
}
.accordion{
    padding-bottom:1em;
}