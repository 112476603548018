
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color:#90b2f5;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.navbar-toggler{
  border-width:1px;
  border-color:white !important;
}
.nav-link{
    color:#00069c !important;
}
.dropdown-menu{

  background-color:#90b2f5;
}
.dropdown-item{
  color:#00069c !important;
}
