.actions {
    padding-top:2em;
    padding-bottom:2em;
    background-color:#f2f2f2;
    color:#545454;
    padding-right:5%;
    padding-left:5%;
}
@media only screen and (min-width: 64.063em) {
    .nea{
        padding-right:15%;
        padding-left:15%;
    }
}