.arxiki {
    padding-top: 2em;
    padding-bottom:2em;
    padding-right:5%;
    padding-left:5%;
    background-color:#f2f2f2;
    color:#545454;
}
.padtop{
    padding-top: 2em;
}

@media only screen and (min-width: 64.063em) {
    .arxiki{
        padding-right:5%;
        padding-left:5%;
    }
}

@media all and (max-width: 1200px){
    .newsFeed{
        padding-bottom:2em;
    }
}