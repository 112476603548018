.action6 {}.action5 {}

.content{
    text-align:justify
}
.justCenter{
    padding-bottom: 1em;
}
.details{
    margin-bottom:0.3rem;
}